import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LocalizationService from '../services/localization-service';
import { Footer, Layout } from '../components';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query NotFoundPageQuery {
      contentfulResourceSet(name: {eq: "pages.not_found"}) {
        name
        resources {
          key
          value {
            value
          }
        }
      }
    }
  `);

  const localizationService = new LocalizationService(data.contentfulResourceSet.resources);

  return <Layout className="not-found-view">
    <section>
      <div className="container v-align-transform">
        <div className="text-center pt-5">
          <i className="fa-solid fa-person-circle-question fa-4x text-muted mb-4"></i>
          <h1 className="large">{localizationService.getString('pages.not_found.title')}</h1>
          <p className="lead pt-4">{localizationService.getString('pages.not_found.body')}</p>
          <div className="row my-5">
            <div className="col-6 col-md-3 col-lg-2 offset-md-3 offset-lg-4 mb-2">
              <a className="btn btn-primary w-100" href="/">{localizationService.getString('pages.not_found.actions.go_home.text')}</a>
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <a className="btn btn-primary w-100" href="/contact">{localizationService.getString('pages.not_found.actions.contact_us.text')}</a>
            </div>
          </div>
        </div>
        <div className="row mb-5 text-center">
          <div className="col-12 mb-2">
            {localizationService.getString('pages.not_found.hints.see_navigation')}
          </div>
          <div className="col-12">
            <i class="fa-solid fa-hand-point-down text-muted fa-4x"></i>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </Layout>;
};

export default NotFoundPage;